.layout-collapse {
  height: 100vh;
  border: 1px solid var(--color-border);
  // background-color: rgb(248 250 252 );

}



.layout-collapse .arco-layout-sider-light .logo {
  background: var(--color-fill-2);
}

.layout-collapse .arco-layout-footer,
.layout-collapse .arco-layout-content {
  color: var(--color-white);
  text-align: center;
  font-stretch: condensed;
  font-size: 16px;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.layout-collapse .arco-layout-footer {
  color: var(--color-text-2);
  height: 48px;
  line-height: 48px;
  font-weight: 400;
  font-size: 14px;
}

.layout-collapse .arco-layout-content {
  color: var(--color-text-2);
  font-weight: 400;
  font-size: 14px;
}

.layout-collapse .arco-layout-header {
  padding: 10px 0;
}

.layout-collapse .arco-layout-header .trigger {
  margin-left: 20px;
}
.arco-menu .arco-menu-item .arco-icon, .arco-menu .arco-menu-group-title .arco-icon, .arco-menu .arco-menu-pop-header .arco-icon, .arco-menu .arco-menu-inline-header .arco-icon{
margin-right: 8px;
}
.arco-menu-vertical .arco-menu-pop-header{
padding: 8px;}


.cascader-wrap .arco-cascader .arco-cascader-view{
background-color: white;
cursor: pointer;
}

.arco-select-multiple .arco-tag{
max-width: 40%;
}


.break-down .arco-form-label-item > label{
  display: flex;
  align-items: center;
  }
  .arco-form-label-item .arco-form-item-symbol svg{
    display: inline-block;
  }
  .basic-infor .arco-card-size-default .arco-card-body{
   padding: 0 16px;
  }
  .arco-select-multiple .arco-tag{
  max-width: 50% !important;
}